import { Button, Collapse, Radio } from "antd";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

const { Panel } = Collapse;

const LoginWorkFlowComp = (props) => {
    // State to store selected options
    const {setting} = props;
    const [selectedOptions, setSelectedOptions] = useState({
        mainOption: setting[1],
        subOption: setting[2],
        attemptOption: setting[3]
    });

    const options = [
        { name:"eStore.storeSettings.login.option1.label", value: 0, labelId: 'Use both for enhanced security', recommended: true },
        { name:"eStore.storeSettings.login.option2.label",value: 1, labelId: 'Quickly access your account with email' },
        {name:"eStore.storeSettings.login.option3.label", value: 2, labelId: 'Simplify your login using just your phone' }
    ];


    // Function to handle changes in the main radio group
    const handleMainOptionChange = (e) => {
        const newOption = e.target.value;
        setSelectedOptions((prevState) => ({
            ...prevState,
            mainOption: newOption,
            subOption: null,  // Reset sub-option on main option change
            attemptOption: null // Reset attempt option on main option change
        }));
        props.updateState({ accordianOpen: newOption });
    };

    // Function to handle changes in the sub-option radio group
    const handleSubOptionChange = (e) => {
        const newSubOption = e.target.value;
        setSelectedOptions((prevState) => ({
            ...prevState,
            subOption: newSubOption
        }));
    };

    // Function to handle changes in the attempt option radio group
    const handleAttemptOptionChange = (e) => {
        const newAttemptOption = e.target.value;
        setSelectedOptions((prevState) => ({
            ...prevState,
            attemptOption: newAttemptOption
        }));
    };

    return (
        <div className="store-model-container cus-space">
            <div className="heading">
                <FormattedMessage id="eStore.storeSettings.login.label" defaultMessage="" />
            </div>
            <FormattedMessage id="eStore.storeSettings.login.subheading" defaultMessage="" />
            <div className="mt20 custom-header">
                <Radio.Group
                    className="radio-grp"
                    onChange={handleMainOptionChange}
                    value={selectedOptions.mainOption}
                >
                    <Collapse
                        accordion
                        expandIconPosition="right"
                        onChange={(e) => props.updateState({ accordianOpen: e })}
                        className="custom-collapse"
                    >
                        {options.map((option) => (
                            <Panel
                                header={
                                    <div className={`option-box ${option.recommended ? 'jus-content' : ''}`}>
                                        <div className="d-flex" style={{ gap: "10px" }}>
                                            <Radio value={option.value} className="radio-btn"></Radio>
                                            <div className="box-content">
                                                <div className="heading">

                                                <FormattedMessage id={option.name} defaultMessage="" />
                                                </div>
                                               
                                               {option.labelId}
                                            </div>
                                        </div>
                                        {option.recommended && <div className="recomm-label">Recommended</div>}
                                    </div>
                                }
                                key={option.value}
                                className="custom-panel"
                            >
                                <div className="selected-box">
                                    <div className="ml35">
                                        <div className="b-text font-14">
                                            <FormattedMessage id="eStore.storeSettings.login.verified" />
                                        </div>
                                        <Radio.Group
                                            className="radio-btn f-row"
                                            onChange={handleSubOptionChange}
                                            value={selectedOptions.subOption}
                                        >
                                            <Radio value={0} className="f-item-center">On Every Login</Radio>
                                            <Radio value={1} className="f-item-center">Only First Login</Radio>
                                        </Radio.Group>
                                        {selectedOptions.subOption ? (
                                            <>
                                                <div className="b-text font-14 mt20">
                                                    <FormattedMessage id="eStore.storeSettings.login.attempts" />
                                                </div>
                                                <Radio.Group
                                                    className="radio-btn f-row"
                                                    onChange={handleAttemptOptionChange}
                                                    value={selectedOptions.attemptOption}
                                                >
                                                    <Radio value={0} className="f-item-center">Only 6 times</Radio>
                                                    <Radio value={1} className="f-item-center">Infinity</Radio>
                                                </Radio.Group>
                                                <div className="note mt20">Customer will be blocked for next 24 hrs.</div>
                                            </>
                                        ):""}
                                    </div>

                                    <div className="ref-box"></div>
                                </div>
                            </Panel>
                        ))}
                    </Collapse>
                </Radio.Group>
            </div>
            <div>
               <Button onClick={()=>{
               let value = 1;

             let a =  Object.values(selectedOptions)?.forEach((i)=>{
                value = (value *10) + i;

               });

               const val = {
                relationshipId: props.relationshipId,
                loginSetting:value
              };
              const modalData ={
                modalBody: (
                  <FormattedMessage
                    id="customer.salesQuote.edit.form.confirmation"
                    defaultMessage=""
                  />
                ),
                handleSubmit: () => {
                  props.hideModal()
                  props.loginSettingSave(val,props);
                 
                }
               }
               props.showModal(modalData)
               }}>
                  Save
                </Button>                
            </div>
        </div>
    );
};

export default injectIntl(LoginWorkFlowComp);
