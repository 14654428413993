import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { Form } from '@ant-design/compatible';
import "@ant-design/compatible/assets/index.css";
import { Drawer, Col, Row, DatePicker, Radio, Skeleton, Input } from "antd";
import "./customerDrawer.scss";
import { CustomButton } from "../../general/CustomButton";
import { TextBox } from "../../general/TextBox";
import { LabelBox } from "../../general/LabelBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import { ErrorMsg } from "../../general/ErrorMessage";
import CustomerTabs from "./CustomerTabs";
import { MODAL_TYPE, CONSTANTS, MODULE_CODED_VALUES } from "../../../static/constants";
import { getLabelName } from "../../modal/modalBody/customerInfo";
import { validateEmail, getMomentDateForUI, showToasterMessage } from "../../../utils";
import find from "lodash.find";
import { checkCodeRanges } from "../../general/stateCode";


function CustomerDrawer(props) {
  const {
    companyName,
    paymentTerms,
    sources,
    industries,
    businessTypes,
    paymentTermName,
    customerSource,
    starRating,
    webSite,
    intl,
    updateDrawerState,
    industryName,
    submittedOnce,
    customerPhone,
    customerEmail,
    contact,
    customerData,
    countryCode
  } = props;
 
  const format = CONSTANTS.DISPLAY_DATE_FORMAT;
  const filteredObjects = props.countries.filter(obj => obj.countryCallingCode !== null);
  const closeDrawer = () => {
    props.updateState({
      customer: {
        customerDrawerVisible: false,
      },
    });
  };

  const rateList = [
    { index: 0, rating: 1 },
    { index: 1, rating: 2 },
    { index: 2, rating: 3 },
    { index: 3, rating: 4 },
    { index: 4, rating: 5 },
  ];

  const handleNewPaymentTermAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, hideModal, companyInfo } = props;
    const data = {
      title: (
        <FormattedMessage id="modal.paymentTerm.heading" defaultMessage="" />
      ),
      formData,
      modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
      hideFooter: true,
      handleSubmit: (formData = {}) => {
        if (
          formData.termName &&
          formData.termName.length &&
          formData.days !== 0 &&
          formData.days
        ) {
          hideModal();
          formData.relationshipId = companyInfo.relationshipId;
          props.addPaymentTerm(formData);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      },
    };
    showModal(data);
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            // props.showModal(data);
            }
        },
      };
      props.pushModalToStack(data);
    }
  };

  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="commons.create.popup.confimation.msg"
          // values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {/* {labelName} ? */}
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.BUSINESS_TYPE: {
        addFunc = props.addBusinessType;
        break;
      }
      case MODAL_TYPE.CUSTOMER_SOURCE: {
        addFunc = props.addCustomerSource;
        break;
      }
      case MODAL_TYPE.INDUSTRY: {
        addFunc = props.addIndustry;
        break;
      }
      default: {
        addFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({
          text: text,
          relationshipId: companyInfo.relationshipId,
          props,
        });
        props.hideModal();
        props.popModalFromStack();

      },
    };
    showModal(modalData);
  };

  const getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        title = (
          <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.CUSTOMER_TYPE: {
        title = (
          <FormattedMessage id="addItem.text.customerType" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.BUSINESS_TYPE: {
        title = <FormattedMessage id="modal.businessType.heading" defaultMessage="" />;
        break;
      }
      case MODAL_TYPE.CUSTOMER_SOURCE: {
        title = (
          <FormattedMessage
            id="modal.customerSource.heading"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.INDUSTRY: {
        title = (
          <FormattedMessage id="addItem.text.industry" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        deleteFunc = props.deletePaymentTerm;
        break;
      }
      case MODAL_TYPE.BUSINESS_TYPE: {
        deleteFunc = props.deleteBusinessType;
        break;
      }
      case MODAL_TYPE.CUSTOMER_SOURCE: {
        deleteFunc = props.deleteCustomerSource;
        break;
      }
      case MODAL_TYPE.INDUSTRY: {
        deleteFunc = props.deleteIndustry;
        break;
      }
      default: {
        deleteFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          props,
        });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

const hasDuplicateTaxIdentificationNumber = (customerTaxIdentificationsList) => {
  const tempList = {};
  
  return customerTaxIdentificationsList.some(customer => {    
      if (tempList[customer.taxIdentificationNumber]) {
          return true;
      }
      tempList[customer.taxIdentificationNumber] = true;
      return false;
  });
}
  const handleSubmit = (payload) => {

    payload.updateDrawerState({ submittedOnce: true, isActive: "1" });
    if (payload.customerType === "individual") {
      if (
        !payload.contact.firstName
        // !payload.contact.emailAddress ||
        // !validateEmail(payload.contact.emailAddress) ||
        // !payload.paymentTermId

      ) {
        return;
      }
    }
    if(payload.contact.emailAddress){
      if (
      !validateEmail(payload.contact.emailAddress) 
    ) {
      return showToasterMessage({ messageType: 'error', description: "Please enter correct email" });
    }
    }
    if(payload?.billingAddress?.countryName ==="India" && payload?.billingAddress?.zipCode && checkCodeRanges(payload.billingAddress?.stateName , payload.billingAddress?.zipCode , true)===false){
      return showToasterMessage({ messageType: 'error', description: "Please enter correct zipcode" });
    }
    // if (payload.contact.emailAddress && !validateEmail(payload.contact.emailAddress)) {
    //   return showToasterMessage({ messageType: 'error', description: "Please enter correct email" });
    // }
    // var billingAddress = payload.billingAddress;
    // var shippingAddress = payload.shippingAddress;
    // var invalidBillingAddress = false;
    // var invalidShippingAddress = false;
    // if ((billingAddress.streetAddress1 || billingAddress.cityName || billingAddress.stateName) && (!billingAddress.streetAddress1 || !billingAddress.cityName || (billingAddress.countryName === 'India' && !billingAddress.stateName))) {
    //   invalidBillingAddress = true;
    // }
    // if ((shippingAddress.streetAddress1 || shippingAddress.cityName || shippingAddress.stateName) && (!shippingAddress.streetAddress1 || !shippingAddress.cityName || (shippingAddress.countryName === 'India' && !shippingAddress.stateName))) {
    //   invalidShippingAddress = true;
    // }
    // if (!payload.paymentTermId) {
    //   return;
    // }
    // if (payload.isUpdate && payload.customerType === 'individual') {
    //   let contactObj = (payload.customerData.boContactList || [])[0] || {};
    //   contactObj.firstName = payload.contact.firstName;
    //   contactObj.lastName = payload.contact.lastName;
    //   payload.customerData.boContactList[0] = contactObj;
    // }
    if (payload.customerType === "business") {
      if (!payload.companyName || !payload.paymentTermId) {
        return;
      }
    }
  if(props.taxIdentificationNumberDublicate){
    return showToasterMessage({ messageType: 'error', description: "Duplicate value" });
  }
  const result = hasDuplicateTaxIdentificationNumber(props.customerTaxIdentificationsList);

  if(result === true){
    return showToasterMessage({ messageType: 'error', description: "Duplicate value" });
  }
    const modalData = {
      title: (
        <FormattedMessage
          id="drawer.customer.confirmation.title"
          defaultMessage=""
        />
      ),
      // modalBody: (
      //   <FormattedMessage
      //     id="drawer.customer.confirmation.text"
      //     defaultMessage=""
      //   />
      // ),
      modalBody:(
        props.isUpdate ? (
          <FormattedMessage id="drawer.customer.confirmation.text" defaultMessage="" />
        ) : (
          <FormattedMessage id="drawer.customer.create.confirmation.text" defaultMessage="" />
        )
      ),
      handleSubmit: () => {
        payload.createOrUpdateCustomer(payload);
        payload.hideModal();
      },
    };
    payload.showModal(modalData);
    // else {
    //   payload.updateDrawerState({
    //     invalidCompanyNameClass: payload.companyName ? '' : 'input-text-error',
    //     invalidBilligCityClass: invalidBillingAddress && !billingAddress.cityName ? 'input-text-error' : '',
    //     invalidBilligStreet1Class: invalidBillingAddress && !billingAddress.streetAddress1 ? 'input-text-error' : '',
    //     invalidBilligStateClass: invalidBillingAddress && !billingAddress.stateName ? 'input-text-error' : '',
    //     invalidShippingCityClass: invalidShippingAddress && !shippingAddress.cityName ? 'input-text-error' : '',
    //     invalidShippingStreet1Class: invalidShippingAddress && !shippingAddress.streetAddress1 ? 'input-text-error' : '',
    //     invalidShippingStateClass: invalidShippingAddress && !shippingAddress.stateName ? 'input-text-error' : '',
    //   });
    // }
  };

  return (
    <Drawer
      title={
        props.isUpdate ? (
          <FormattedMessage id="drawer.customerEdit.title" defaultMessage="" />
        ) : (
          <FormattedMessage id="drawer.customer.title" defaultMessage="" />
        )
      }
      width={720}
      zIndex={1}
      keyboard={false}
      destroyOnClose={true}
      maskClosable={false}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.customerDrawerVisible}
      afterVisibleChange={(visible) => {
        if (visible) {
          props.initCalls();
        }
      }}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => handleSubmit(props)}
            key={122}
          />
        </>
      }
    >
      <>
        <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="common-label">
                <FormattedMessage
                  id="drawer.customer.customerType"
                  defaultMessage=""
                />
              </div>
              <Radio.Group
                className="radio-grp"
                onChange={(e) => {
                  updateDrawerState({
                    customerType: e.target.value,
                  });
                }}
                value={props.customerType || "individual"}
              >
                <Radio value={"individual"}>
                  <FormattedMessage id="individual" />
                </Radio>
                <Radio value={"business"}>
                  <FormattedMessage id="business" />
                </Radio>
              </Radio.Group>
            </Col>

            {props.customerType === "individual" ? (
              <>
                <Col span={12}>
                  <div className="common-label">
                    <FormattedMessage id="contact.firstName" />
                    <span className="required">*</span>
                  </div>
                  <TextBox
                    className="textbox-pd"
                    placeholder={intl.formatMessage({
                      id: "contact.firstName.placeholder",
                      defaultMessage: "",
                    })}
                    type="text"
                    showCount
                    maxLength={50}
                    countStyle={{ top: "43px" }}
                    value={contact.firstName}
                    onChange={(e) => {
                      updateDrawerState({
                        contact: { ...contact, firstName: e.target.value },
                      });
                      if (props.customerType === "individual") {
                        updateDrawerState({
                          companyName:
                            (e.target.value || "") +
                            " " +
                            (contact.lastName || ""),
                        });
                      }
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !submittedOnce || !!contact.firstName;
                    }}
                    message={intl.formatMessage({
                      id: "firstName.requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>

                <Col span={12}>
                  <div className="common-label">
                    <FormattedMessage id="contact.lastName" />
                  </div>

                  <TextBox
                    className="textbox-pd"
                    placeholder={intl.formatMessage({
                      id: "contact.lastName.placeholder",
                      defaultMessage: "",
                    })}
                    type="text"
                    maxLength={50}
                    countStyle={{ top: "43px" }}
                    value={contact.lastName}
                    onChange={(e) => {
                      updateDrawerState({
                        contact: { ...contact, lastName: e.target.value },
                      });
                      if (props.customerType === "individual") {
                        updateDrawerState({
                          companyName:
                            (contact.firstName || "") +
                            " " +
                            (e.target.value || ""),
                        });
                      }
                    }}
                  />
                  {/* <ErrorMsg
                    validator={() => {
                      return !submittedOnce || !!contact.lastName;
                    }}
                    message={intl.formatMessage({
                      id: "lastName.requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  /> */}
                </Col>

                <Col span={12}>
                  <div className="common-label">
                    <FormattedMessage id="contact.email" />
                    {/* <span className="required">*</span> */}
                  </div>
                  <TextBox
                    placeholder={intl.formatMessage({
                      id: "drawer.customer.contact.email.placeholder",
                      defaultMessage: "",
                    })}
                    type="text"
                    maxLength={50}
                    countStyle={{ top: "43px" }}
                    className={props.invalidEmailClass}
                    // validator={(val) => { return validateEmail(val) || !val
                    //   ? ""
                    //   : "input-text-error"; }}
                    // className="textbox-pd"
                    value={contact?.emailAddress}
                    onChange={(e) => {
                      updateDrawerState({
                        contact: { ...contact, emailAddress: e.target.value },
                        invalidEmailClass:
                          validateEmail(e.target.value) || !e.target.value
                            ? ""
                            : "input-text-error",
                      });
                    }}
                  />
             
                  {/* <ErrorMsg
                    validator={() => {
                      return !submittedOnce || !!contact.emailAddress;
                    }}
                    message={intl.formatMessage({
                      id: "email.requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  /> */}


                </Col>

                <Col span={12}>
                  <div className="common-label">
                    Mobile Number<span className="required">*</span>
                  </div>
                  <div style={{ display: "flex" }}>

                    {/* <Dropdown

                      style={{ width: "20%" }}
                      items={filteredObjects || []}
                      valueKeyName="countryCallingCode"
                      optionKeyName="countryId"

                      // value={filteredObjects.countryCallingCode || props.companyInfo.countryCallingCode
                      //   ? "+" + props.companyInfo.countryCallingCode
                      //   : "-"}
                      value={props.companyInfo.countryCallingCode
                          ? "+" + props.companyInfo.countryCallingCode
                          : "-"}
                      onSelect={(e) => {
                         updateDrawerState({
                          contact: { ...contact, cellPhone: e.target.value },
                        });
                        props.fetchCountries({
                          countryId: e,
                          relationshipId: (props.companyInfo || {}).relationshipId,
                        });

                      }}

                    /> */}
                    <DropdownRef
                      style={{ width: "20%" }}
                      items={
                        (props.countries || []).filter((el) => {
                          return el.countryCallingCode != null;
                        }) || []
                      }
                      optionKeyName="countryId"
                      valueKeyName="countryCallingCode"
                      defaultValue={props.countryCallingCode}
                      className="currency-tags"
                      onSelect={(selectedValue, option) => {
                        const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);

                        if (selectedCountry) {
                          updateDrawerState({
                            countryCallingCode: selectedCountry.countryCallingCode,
                            contact: { ...contact, countryCallingCode: selectedCountry.countryCallingCode },
                          });
                        }

                      }}
                    />
                    <TextBox
                      className="textbox-pd"
                      maxLength={10}
                      countStyle={{ top: "43px" }}
                      value={contact?.cellPhone}
                      placeholder={intl.formatMessage({
                        id: "drawer.customer.contact.mobile.placeholder",
                        defaultMessage: "",
                      })}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          updateDrawerState({
                            contact: { ...contact, cellPhone: e.target.value },
                          });
                        }
                      }}
                    />
                  </div>

                  {/* <LabelBox
                  labelValue={
                    props.companyInfo.countryCallingCode
                      ? "+" + props.companyInfo.countryCallingCode
                      : "-"
                  }
                  labelStyle={{ marginRight: "-41px" }}
                  placeholder={intl.formatMessage({
                    id: "drawer.customer.contact.mobile.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={10}
                  value={contact.cellPhone}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      updateDrawerState({
                        contact: { ...contact, cellPhone: e.target.value },
                      });
                    }
                  }}
                /> */}
                  <ErrorMsg
                    validator={() => {
                      return !submittedOnce || !!contact.cellPhone;
                    }}
                    message={intl.formatMessage({
                      id: "mobileNo.requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>

                <Col span={12}>
                  <div className="common-label">Fax</div>
                  <TextBox
                    className="textbox-pd"
                    placeholder={intl.formatMessage({
                      id: "drawer.customer.contact.faxNumber.placeholder",
                      defaultMessage: "",
                    })}
                    type="text"
                    maxLength={15}
                    countStyle={{ top: "43px" }}
                    value={contact.faxNumber}
                    onChange={(e) => {
                      updateDrawerState({
                        contact: { ...contact, faxNumber: e.target.value },
                      });
                    }}
                  />
                </Col>
              </>
            ) : (
              ""
            )}

            {!(props.customerType === "individual") ? (
              <Col span={12}>
                <div className="common-label">
                  <FormattedMessage
                    id="drawer.customer.companyName.placeholder"
                    defaultMessage=""
                  />
                  <span className="required">*</span>
                </div>

                <TextBox
                  className="textbox-pd"
                  maxLength={100}
                  countStyle={{ top: "43px" }}
                  type="text"
                  value={companyName}
                  disabled={props.customerType === "individual"}
                  onChange={(e) => {
                    updateDrawerState({
                      companyName: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    props.isDuplicateCustomer({
                      relationshipId: props.companyInfo.relationshipId,
                      customerName: props.companyName,
                    });
                  }}
                  placeholder={intl.formatMessage({
                    id: "drawer.customer.companyName.placeholder",
                    defaultMessage: "",
                  })}
                />
                <ErrorMsg
                  validator={() => {
                    return !submittedOnce || !!companyName;
                  }}
                  message={intl.formatMessage({
                    id: "companyName.requiredField.missing.msg",
                    defaultMessage: "",
                  })}
                />
              </Col>
            ) : (
              ""
            )}

            <Col span={12}>
              <div className="common-label">
                <FormattedMessage
                  id="drawer.customer.paymentTerm"
                  defaultMessage=""
                />
                {/* <span className="required">*</span> */}
              </div>
              <Dropdown
                items={(paymentTerms || []).sort((a, b) => a.termName.localeCompare(b.termName))}
                valueKeyName="termName"
                optionKeyName="paymentTermId"
                value={
                  paymentTerms.length
                    ? paymentTermName || props.paymentTermId
                    : ""
                }
                placeholder={intl.formatMessage({
                  id: "common.select.placeholder",
                  defaultMessage: "",
                })}
                canAddNew={true}
                canDelete={true}
                moduleName ={MODULE_CODED_VALUES.CUSTOMER_PAYMENT}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.PAYMENT_TERM, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    termName: payload.textEntered,
                    days: null,
                    description: "",
                    isDefaultPayment: false,
                    submittedOnce: false,
                  };
                  handleNewPaymentTermAddition(
                    props,
                    payload,
                    MODAL_TYPE.PAYMENT_TERM
                  );
                }}
                onSelect={(optionValue, obj) => {
                  updateDrawerState({
                    paymentTermId: optionValue,
                    paymentTermName: obj.name,
                  });
                }}
                allowClear={true}
                onClear={() => {
                  updateDrawerState({
                    paymentTermId: undefined,
                    paymentTermName: undefined,
                  });
                }}
              />
              {/* <ErrorMsg
                validator={() => {
                  return (
                    !submittedOnce || !!(paymentTermName || props.paymentTermId)
                  );
                }}
                message={intl.formatMessage({
                  id: "paymentTerm.requiredField.missing.msg",
                  defaultMessage: "",
                })}
              /> */}
            </Col>

            <Col span={12}>
              <div className="common-label">
                <FormattedMessage
                  id="drawer.customer.customerSource"
                  defaultMessage=""
                />

              </div>
              <Dropdown
                items={(sources || []).sort((a, b) => a.customerSourceName.localeCompare(b.customerSourceName))}
                valueKeyName="customerSourceName"
                optionKeyName="customerSourceId"
                value={
                  sources.length ? customerSource || props.customerSourceId : undefined
                }
                placeholder={intl.formatMessage({
                  id: "common.select.placeholder",
                  defaultMessage: "",
                })}
                canAddNew={true}
                canDelete={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.CUSTOMER_SOURCE, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false,
                  };
                  handleNewItemAddition(
                    props,
                    payload,
                    MODAL_TYPE.CUSTOMER_SOURCE
                  );
                }}
                onSelect={(optionValue) => {
                  updateDrawerState({
                    customerSourceId: optionValue,
                  });
                }}
                allowClear={true}
                onClear={() => {
                  updateDrawerState({
                    customerSourceId: undefined,
                  });
                }}
              />
            </Col>

            {!(props.customerType === "individual") ? (
              <>
                <Col span={12}>
                  <div className="common-label">
                    {" "}
                    <FormattedMessage id="businessType.text" defaultMessage="" />
                    {/* <span className="required">*</span> */}
                  </div>
                  <Dropdown
                    items={businessTypes}
                    valueKeyName="businessType"
                    optionKeyName="businessTypeId"
                    value={businessTypes.length ? props.businessType : ""}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                      deleteClickHandler(
                        props,
                        MODAL_TYPE.BUSINESS_TYPE,
                        payload
                      );
                    }}
                    onSelect={(businessTypeId, obj) => {
                      updateDrawerState({
                        businessTypeId,
                        businessType: obj.name,
                      });
                    }}
                    onAddNewClick={(payload) => {
                      payload.formData = {
                        textBoxValue: payload.textEntered,
                        submittedOnce: false,
                      };
                      handleNewItemAddition(
                        props,
                        payload,
                        MODAL_TYPE.BUSINESS_TYPE
                      );
                    }}
                    placeholder={intl.formatMessage({
                      id: "common.select.placeholder",
                      defaultMessage: "",
                    })}
                    allowClear={true}
                    onClear={() => {
                      updateDrawerState({
                        businessTypeId: undefined,
                        businessType: undefined,
                      });
                    }}
                  />
                  {/* <ErrorMsg
                validator={() => {
                  return !submittedOnce || !!props.businessType;
                }}
                message={intl.formatMessage({
                  id: "businessType.requiredField.missing.msg",
                  defaultMessage: "",
                })}
              /> */}
                </Col>

                <Col span={12}>
                  <div className="common-label">
                    <FormattedMessage
                      id="drawer.customer.websiteUrl"
                      defaultMessage=""
                    />
                  </div>
                  <TextBox
                    maxLength={100}
                    countStyle={{ top: "43px" }}
                    type="text"
                    className="textbox-pd"
                    value={webSite}
                    placeholder={intl.formatMessage({
                      id: "drawer.customer.websiteUrl.placeholder",
                      defaultMessage: "",
                    })}
                    onChange={(e) => {
                      updateDrawerState({
                        webSite: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col span={12}>
                  <div className="common-label">
                    <FormattedMessage
                      id="drawer.customer.rating"
                      defaultMessage=""
                    />
                  </div>
                  <Dropdown
                    items={rateList}
                    valueKeyName="rating"
                    value={starRating}
                    optionKeyName="rating"
                    placeholder={intl.formatMessage({
                      id: "common.select.placeholder",
                      defaultMessage: "",
                    })}
                    onSelect={(optionValue, opt) => {
                      updateDrawerState({ starRating: optionValue });
                    }}
                    allowClear={true}
                    onClear={() => {
                      updateDrawerState({
                        starRating: undefined,
                      });
                    }}
                  />
                </Col>

                <Col span={12}>
                  <div className="common-label">
                    <FormattedMessage
                      id="drawer.customer.industry"
                      defaultMessage=""
                    />
                  </div>
                  <Dropdown
                    items={industries}
                    valueKeyName="industryName"
                    value={
                      industries.length
                        ? industryName || props.businessIndustryId
                        : ""
                    }
                    optionKeyName="businessIndustryId"
                    placeholder={intl.formatMessage({
                      id: "common.select.placeholder",
                      defaultMessage: "",
                    })}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                      deleteClickHandler(props, MODAL_TYPE.INDUSTRY, payload);
                    }}
                    onSelect={(businessIndustryId, obj) => {
                      updateDrawerState({
                        businessIndustryId,
                        industryName: obj.name,
                      });
                    }}
                    onAddNewClick={(payload) => {
                      payload.formData = {
                        textBoxValue: payload.textEntered,
                        submittedOnce: false,
                      };
                      handleNewItemAddition(props, payload, MODAL_TYPE.INDUSTRY);
                    }}
                    allowClear={true}
                    onClear={() => {
                      updateDrawerState({
                        businessIndustryId: undefined,
                        industryName: undefined,
                      });
                    }}
                  />
                </Col>

                <Col span={12}>
                  <div className="common-label">
                    <FormattedMessage
                      id="drawer.customer.relationshipSince"
                      defaultMessage=""
                    />
                  </div>
                  <DatePicker
                    getPopupContainer={(trigger) => trigger.parentNode}
                    format={format}
                    key={`${props.relationshipSince
                      ? getMomentDateForUI({
                        date: new Date(props.relationshipSince),
                        format,
                      })
                      : null
                      }`}
                    defaultValue={
                      props.relationshipSince
                        ? getMomentDateForUI({
                          date: new Date(props.relationshipSince),
                          format,
                        })
                        : null
                    }
                    onChange={(selectedDate) => {
                      updateDrawerState({
                        relationshipSince: selectedDate,
                      });
                    }}
                    placeholder={intl.formatMessage({
                      id: "drawer.customer.relationshipSince.placeholder",
                      defaultMessage: "",
                    })}
                  />
                </Col>
                <Col span={12}>
                  <div className="common-label">
                    <FormattedMessage id="contact.email" />
                    
                  </div>
                  <TextBox
                    placeholder={intl.formatMessage({
                      id: "drawer.customer.contact.email.placeholder",
                      defaultMessage: "",
                    })}
                    type="text"
                    maxLength={50}
                    countStyle={{ top: "43px" }}
                    className={props.invalidEmailClass}
                    // validator={(val) => { return validateEmail(val) || !val
                    //   ? ""
                    //   : "input-text-error"; }}
                    // className="textbox-pd"
                    value={customerEmail}
                    
                    onChange={(e) => {
                      updateDrawerState({
                        customerEmail: e.target.value ,
                        invalidEmailClass:
                          validateEmail(e.target.value) || !e.target.value
                            ? ""
                            : "input-text-error",
                      });
                    }}
                  />
             
                 


                </Col>

                <Col span={12}>
                  <div className="common-label">
                    Mobile Number
                  </div>
                  <div style={{ display: "flex" }}>

                    {/* <Dropdown

                      style={{ width: "20%" }}
                      items={filteredObjects || []}
                      valueKeyName="countryCallingCode"
                      optionKeyName="countryId"

                      // value={filteredObjects.countryCallingCode || props.companyInfo.countryCallingCode
                      //   ? "+" + props.companyInfo.countryCallingCode
                      //   : "-"}
                      value={props.companyInfo.countryCallingCode
                          ? "+" + props.companyInfo.countryCallingCode
                          : "-"}
                      onSelect={(e) => {
                         updateDrawerState({
                          contact: { ...contact, cellPhone: e.target.value },
                        });
                        props.fetchCountries({
                          countryId: e,
                          relationshipId: (props.companyInfo || {}).relationshipId,
                        });

                      }}

                    /> */}
                    <DropdownRef
                      style={{ width: "20%" }}
                      items={
                        (props.countries || []).filter((el) => {
                          return el.countryCallingCode != null;
                        }) || []
                      }
                      optionKeyName="countryId"
                      valueKeyName="countryCallingCode"
                      defaultValue={props.countryCallingCode}
                      className="currency-tags"
                      onSelect={(selectedValue, option) => {
                        const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);

                        if (selectedCountry) {
                          updateDrawerState({
                            countryCallingCode: selectedCountry.countryCallingCode,
                            contact: { ...contact, countryCallingCode: selectedCountry.countryCallingCode },
                          });
                        }

                      }}
                    />
                    <TextBox
                      className="textbox-pd"
                      maxLength={10}
                      countStyle={{ top: "43px" }}
                      value={customerPhone}
                      placeholder={intl.formatMessage({
                        id: "drawer.customer.contact.mobile.placeholder",
                        defaultMessage: "",
                      })}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          updateDrawerState({
                             customerPhone: e.target.value,
                          });
                        }
                      }}
                    />
                  </div>

                  {/* <LabelBox
                  labelValue={
                    props.companyInfo.countryCallingCode
                      ? "+" + props.companyInfo.countryCallingCode
                      : "-"
                  }
                  labelStyle={{ marginRight: "-41px" }}
                  placeholder={intl.formatMessage({
                    id: "drawer.customer.contact.mobile.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={10}
                  value={contact.cellPhone}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      updateDrawerState({
                        contact: { ...contact, cellPhone: e.target.value },
                      });
                    }
                  }}
                /> */}
                 
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>

          <CustomerTabs {...props} />
        </Skeleton>
      </>
    </Drawer>
  );
}

export default injectIntl(CustomerDrawer);
