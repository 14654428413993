import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextBox } from "../../../../general/TextBox";
import { Row, Col, Button, Checkbox, Switch } from "antd";
import { MODAL_TYPE, ICONS } from "../../../../../static/constants";
import SelectedProductModal from "./productSelection";
import CategorySelection from "./categorySelection";
import { version } from "jszip";

function Return(props) {
    const { intl, returnSetting={}, replaceSameAsReturn, replaceSetting } = props;
    const handleParentCategory = () => {
        props.pushModalToStack({
            modalBody: <CategorySelection {...props} replaceSet={false} cancelSet={true} returnSet={true} />,
            title: (
                <FormattedMessage
                    id="drawer.inventory.select.category.placeholder"
                    defaultMessage=""
                />
            ),
            width: "25%",
            hideTitle: false,
            hideFooter: true,
            wrapClassName: "cat-modal-custom",
        });
    };
    const handleSelectedProduct = () => {
        props.pushModalToStack({
            modalBody: <SelectedProductModal {...props} cancelSet={true} replaceSet={false} returnSet={true} />,
            title: (<FormattedMessage
                id="selectProduct.modal.title"
                defaultMessage="" />),
            width: "25%",
            hideTitle: false,
            hideFooter: true,
            wrapClassName: "cat-modal-custom",
        });
    };

    const getCategoryNames = () => {
        const { notReturnableCategoryRefs } = returnSetting;
        const categoryNames = (notReturnableCategoryRefs || []).map((ref) => {
            const matchingCategory = props.productCategories.find(
                (category) => category.inventoryItemCategoryId === ref.inventoryItemCategoryId
            );
            return matchingCategory ? matchingCategory.categoryName : "";
        });
        return categoryNames?.join(", ");
    };

    const addReturnReason = (payload) => {
        const { showModal, hideModal } = props;
        const data = {
            title: "Reason",
            modalData: { modalType: MODAL_TYPE.RETURN_REASON, data: payload },
            hideFooter: false,
            handleSubmit: (value) => {
                let rasons = returnSetting.returnReason || [];
                rasons.push({ reason: value.textBoxValue, available: 0 });

                props.updateState({
                    returnSetting: {
                        ...returnSetting,
                        returnReason: rasons,
                    },
                })
                hideModal();
            },
        };
        showModal(data);
    };
    const handleSave = () => {
        const payload = {
            ecomReturnSettingId: returnSetting.ecomReturnSettingId || 0,
            relationshipId: props.companyInfo.relationshipId,
            isActive: returnSetting.isActive,
            returnDuration: returnSetting.returnDuration,
            isOnlyByRequest:returnSetting.isOnlyByRequest,
            type: "Return",
            displayMessage: returnSetting.displayMessage,
            notReturnableCategoryRefs: returnSetting.notReturnableCategoryRefs?.length && (returnSetting.notReturnableCategoryRefs || []).map((val) => {
                return {
                    inventoryItemCategoryId: val.inventoryItemCategoryId || null,
                    relationshipId: props.relationshipId,
                    notReturnableCategoryRefsId:val.notReturnableCategoryRefsId|| 0
                }
            }) || [{}],

            notReturnableInventoryItemRefs: (returnSetting.notReturnableInventoryItemRefs || []).map(itemId => ({
                itemId: itemId,
                relationshipId: props.relationshipId,
                notReturnableInventoryItemRefsId:0,

            })) || [{}],
            returnReasons: JSON.stringify(returnSetting.returnReason || `{}`),
            version:props.version

        }
        const modalData = {
            modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />,
            handleSubmit: () => {
                props.hideModal();
                if (replaceSameAsReturn === 1) {
                    let pay = { ...payload, type: "Replace" }
                    props.saveReturnSettings([payload, pay], props);
                }
                else {
                    props.saveReturnSetting(payload, props);

                }
            },
        };
        props.showModal(modalData);
    }
    return <>
        <div className="heading">
            <FormattedMessage id="eStore.storeSettings.return.label" defaultMessage="" />
        </div>
        <div className="model-container pt20">
            <Row gutter={[16, 16]}>
                <Col span={24} className="strong-txt">Allow Customers</Col>
                <Col span={12}>
                    <div>Return Order Items </div>
                    Customers can return the delivered order items.
                </Col>
                <Col span={12}>
                    <Switch checked={returnSetting.isActive === 1} onChange={(checked) => {
                        props.updateState({

                            returnSetting: { ...returnSetting, isActive: checked ? 1 : 0 },
                        })
                    }} />
                </Col>

            </Row>
            {returnSetting.isActive ?
                <>
            <Row gutter={[16, 16]}>
                <Col span={24} className="strong-txt">Include Shipping Charge</Col>
                <Col span={12}>
                    Customers will be charged the shipping charge on returning the delivered order.
                </Col>
                <Col span={12}>
                    <Switch checked={returnSetting?.isOnlyByRequest === 1} onChange={(checked) => {
                        props.updateState({

                            returnSetting: { ...returnSetting, isOnlyByRequest: checked ? 1 : 0 },
                        })
                    }} />
                </Col>

            </Row>
                <Row gutter={[16, 8]}>
                    <Col span={24} className="strong-txt mt20">Return Duration</Col>


                    <Col span={6}>
                        <TextBox suffix="days" value={returnSetting?.returnDuration||""} onChange={(e) => {
                            props.updateState({
                                returnSetting: { ...returnSetting, returnDuration: e.target.value },

                            });
                        }} />
                    </Col>
                    {returnSetting?.returnDuration && <Col span={24}>
                        Return will be allowed till {returnSetting?.returnDuration||0} days after delivery.
                    </Col>}
                </Row>
                    <Row gutter={[16, 16]}>
                    <Col span={24} className="strong-txt mt20">Return Reason</Col>

                        {(returnSetting?.returnReason||[])?.length ? (returnSetting?.returnReason || [])?.map((item, index) => (
                            <Col span={3} key={index}>
                                <Checkbox
                                    checked={item.available}
                                    onChange={(e) => props.updateState({
                                        returnSetting: {
                                            ...returnSetting,
                                            returnReason: (returnSetting?.returnReason||[]).map(reason =>
                                                reason.reason === item.reason
                                                    ? { ...reason, available: e.target.checked ? 1 : 0 }
                                                    : reason
                                            )
                                        },
                                    })}
                                    className="mr5"
                                />
                                {item.reason}

                                <div
                                    onClick={() => {
                                        props.updateState({
                                            returnSetting: {
                                                ...returnSetting,
                                                returnReason: returnSetting.returnReason.filter(reason => reason.reason !== item.reason),
                                            }
                                        });
                                    }}
                                    style={{ cursor: "pointer", color: "red" }}
                                >
                                    Delete
                                </div>
                            </Col>
                        )):""}

                    </Row><Col>
                        <Button onClick={() => {
                            addReturnReason(
                                returnSetting,
                                MODAL_TYPE.RETURN_REASON);
                        }}>Add More</Button>
                    </Col><Row gutter={[16, 8]}>
                        <Col span={24} className="strong-txt mt20">
                            Remove Return from Product & Category
                        </Col>
                        <Col span={24} className='i-label'>Category</Col>
                        <Col span={6}>
                            <div onClick={() => handleParentCategory()} onFocus={handleParentCategory}>
                                <TextBox
                                    value={getCategoryNames()}

                                    placeholder={intl.formatMessage({
                                        id: "drawer.inventory.select.category.placeholder",
                                        defaultMessage: "",
                                    })}
                                />
                            </div>
                        </Col>

                        <Col span={6}>
                            {(returnSetting.notReturnableInventoryItemRefs || []).length ?
                                <div onClick={() => handleSelectedProduct()} className="count-box">

                                    {(returnSetting.notReturnableInventoryItemRefs || [])?.length}
                                    {/* {getProductNames()} */}
                                </div>
                                : ""
                            }
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            {getCategoryNames() ? (
                                <>
                                    <div>Exempt Message</div>
                                    {!props.editMode ?

                                        <div onClick={() => {
                                            props.updateState({ editMode: true });
                                        }}>
                                            <span className="mr5">

                                                {returnSetting.displayMessage ||"Return not applicable on this Product/Category as per the Return Policy"}
                                            </span>
                                            <i className={`${ICONS.EDIT} action-delete`} />
                                        </div>
                                        : ""}
                                </>
                            ) : (
                                ""
                            )}

                            {props.editMode && (
                                <TextBox 
                                value={returnSetting.displayMessage}
                                    onChange={(e) => {
                                        props.updateState({
                                            returnSetting: { ...returnSetting, displayMessage: e.target.value },
                                        });
                                    }} />
                            )}

                        </Col>
                     
                    </Row>
                    <div className="footer-btn" >
                        <Button type="primary" onClick={() => handleSave()}>Submit</Button>
                    </div>
                </>

                : ""}
        </div>
    </>
}
export default injectIntl(Return);