import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDataIfNeeded } from "../../../utils";
import { CONSTANTS } from "../../../static/constants";
import CustomerDrawer from "../../../components/drawer/customer/CustomerDrawer";
import {
  fetchCountries,
  fetchStates,
  fetchSalutation,
  showModal,
  hideModal,
  fetchPaymentTerms,
  addPaymentTerm,
  deletePaymentTerm,
  fetchBusinessTypes,
  addBusinessType,
  deleteBusinessType,
  fetchIndustries,
  addIndustry,
  deleteIndustry,
  fetchSources,
  addCustomerSource,
  deleteCustomerSource,
  fetchTaxIdentifications,
  addTaxIdentification,
  deleteTaxIdentifications,
  addSalutation,
  deleteSalutation,
  pushModalToStack,
  popModalFromStack,
} from "./../../../actions/commonActions";
import {
  createOrUpdateCustomer,
  isDuplicateCustomer,
  fetchAllGSTINDetails,
} from "./action";
import { getAllLeadSources } from "../../modal/modalBody/leads/createLead/action";
import { checktaxIdentificationNumber } from "../TaxIdentification/action";

class Customer extends Component {
  constructor(props) {
    super(props);
    const { isUpdate, customerData, customerName, isClone } = props;
    
    const needToInIt = (isUpdate || isClone) && customerData ? true : false;
    const contact = needToInIt
      ? (customerData.boContactList || [])[0] || {}
      : {};
     
    const companyInfo = this.props.companyInfo || {};
    this.state = {
      customerId: isUpdate ? customerData.customerId : undefined,
      crmLeadId: isUpdate ? customerData.crmLeadId : "",
      leadStatusId: isUpdate ? customerData.leadStatusId : "",
      leadStatusName: isUpdate ? customerData.leadStatusName : "",
      version: needToInIt ? customerData.version : undefined,
      companyName: needToInIt
        ? customerData.companyName
        : customerName
        ? customerName
        : undefined,
      paymentTermName: needToInIt ? customerData.paymentTermName : undefined,
      paymentTermId: needToInIt
        ? Number(customerData.paymentTermId || 0) || undefined
        : undefined,
      customerType: needToInIt
        ? customerData.customerType || undefined
        : "individual",
      businessType: needToInIt
        ? customerData.businessType || undefined
        : undefined,
      customerPhone: isUpdate
        ? customerData?.customerPhone || undefined
        : undefined,
      customerEmail: isUpdate
        ? customerData?.customerEmail || undefined
        : undefined,
      businessTypeId: needToInIt
        ? customerData.businessTypeId || undefined
        : undefined,
      webSite: needToInIt ? customerData.webSite : undefined,
      relationshipSince:
        needToInIt && customerData.relationshipSince
          ? new Date(customerData.relationshipSince)
          : undefined,
      customerSource: undefined,
      // customerSourceId: needToInIt ? customerData.sourceId || undefined : undefined,
      customerSourceId: needToInIt
        ? customerData.sourceId || undefined
        : undefined,
      starRating: needToInIt ? customerData.starRating || undefined : undefined,
      industryName: undefined,
      //allTaxIdentificatinType: [],
      businessIndustryId: needToInIt
        ? customerData.industryId || undefined
        : undefined,
      shippingAddressSameAsBilling: 0,
      gstMechanism: needToInIt ? customerData.gstMechanism : undefined,
      gstNumber: needToInIt ? customerData.gstNumber : undefined,
      panNumber: needToInIt ? customerData.panNumber : undefined,
      locatedInSez: needToInIt ? customerData.locatedInSez : 0,
      customerTaxIdentificationsList: needToInIt
        ? customerData.customerTaxIdentificationsList
        : [{}],
      billingAddress: {
        streetAddress1: undefined,
        streetAddress2: undefined,
        cityName: undefined,
        stateName: undefined,
        zipCode: undefined,
        locationName: undefined,
        countryName: undefined,
      },
      gstinNumber: undefined,
      shippingAddress: {
        streetAddress1: undefined,
        streetAddress2: undefined,
        cityName: undefined,
        stateName: undefined,
        zipCode: undefined,
        locationName: undefined,
        countryName: undefined,
      },
      contact: {
        salutationId: undefined,
        firstName: needToInIt ? contact.firstName || undefined : undefined,
        middleName: undefined,
        lastName: needToInIt ? contact.lastName || undefined : undefined,
        preferCallName: undefined,
        workPhone: needToInIt ? contact.cellPhone : "",
        cellPhone: needToInIt ? contact.cellPhone : "",
        faxNumber: needToInIt ? contact.faxNumber : "",
        emailAddress: needToInIt ? contact.emailAddress : "",
        contactType: needToInIt ? contact.emailAddress : "",
        salutationName: undefined,
        countryCallingCode: companyInfo.countryCallingCode,
      },

      gstDetails: {
       
        gstin: "",
        businessName: "",
        state: "",
        registrationDate: "",
        constitute: "",
        taxPayerType: "",
        natureOfBussiness: "",
        gstinStatus: "",
        stateJurisdication: "",
        cancellationDate: "",
        lastUpdateDate: "",
        stateCode: "",
        centerName: "",
        centerCode: "",
        tradeName: "",
        businessFields: "",
        businessAddress: "",
        buildingName: "",
        streetName: "",
        location: "",
        doorNumber: "",
        stateName: "",
        floorNo: "",
        latitude: "",
        longitude: "",
        pincode: "",
        natureofAdditionalBusiness: "",
        principalPlaceOfBusiness: "",
        principalPlaceOfAddress: "",
      },

      props2: {
        action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
        listType: "picture",
        defaultFileList: [],
        className: "upload-list-inline",
      },
      fileToUpload: [],
      countryCallingCode: companyInfo.countryCallingCode,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId };
    fetchDataIfNeeded(
      "fetchTaxIdentifications",
      "allTaxIdentificatinType",
      this.props,
      payload
    );
  }

  initCalls() {
    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId };
    fetchDataIfNeeded("fetchCountries", "countries", this.props);
    fetchDataIfNeeded("fetchSources", "sources", this.props, payload);
    fetchDataIfNeeded(
      "fetchBusinessTypes",
      "businessTypes",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchPaymentTerms", "paymentTerms", this.props, payload);
    fetchDataIfNeeded("fetchIndustries", "industries", this.props, payload);
    fetchDataIfNeeded("fetchSalutation", "salutations", this.props, payload);
    this.props.getAllLeadSources(payload);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.countries !== this.props.countries && nextProps.countries) {
      let obj =
        nextProps.countries.find(
          (country) =>
            country.countryName === this.props.companyInfo.countryName
        ) || {};
      this.setState({
        shippingAddress: {
          ...this.state.shippingAddress,
          countryName: obj.countryName || "",
          countryId: obj.countryId,
        },
        billingAddress: {
          ...this.state.billingAddress,
          countryName: obj.countryName || "",
          countryId: obj.countryId,
        },
      });
      if (
        obj.countryId === CONSTANTS.INDIA_COUNTRY_ID ||
        obj.countryName === "United States" ||
        obj.countryName === "United States (USA)"
      ) {
        this.props.fetchStates(obj.countryId);
      }
    }
   
   

    // if(this.props.paymentTerms.length && this.state.paymentTermId && !this.state.paymentTermName){
    //   let paymentTerm = (this.props.paymentTerms.find(ind => (ind.paymentTermId === Number(this.state.paymentTermId)))) || {};
    //   this.setState({
    //    paymentTermName: paymentTerm.termName,
    //    paymentTermId: paymentTerm.paymentTermId
    //   })
    // }
  }

  render() {
    return (
      <div>
        <CustomerDrawer
          initCalls={() => {
            this.initCalls();
          }}
          {...this.state}
          {...this.props}
         
          updateDrawerState={(data) => {
            this.setState(data);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countries,
    states: state.common.states,
    sources: state.common.sources,
    businessTypes: state.common.businessTypes,
    paymentTerms: state.common.paymentTerms,
    industries: state.common.industries,
    isLoading: state.common.isLoading,
    salutations: state.common.salutations,
    companyInfo: state.common.companyInfo,
    allTaxIdentificatinType: state.common.allTaxIdentificatinType,
    crmLeadSourceList: state.crm.crmLeadSourceList,
    drawerLoading: state.common.drawerLoading,
   
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCountries,
      fetchStates,
      fetchSources,
      addCustomerSource,
      deleteCustomerSource,
      fetchPaymentTerms,
      addPaymentTerm,
      deletePaymentTerm,
      fetchIndustries,
      addIndustry,
      deleteIndustry,
      fetchBusinessTypes,
      addBusinessType,
      deleteBusinessType,
      fetchSalutation,
      createOrUpdateCustomer,
      showModal,
      hideModal,
      fetchTaxIdentifications,
      addTaxIdentification,
      deleteTaxIdentifications,
      addSalutation,
      deleteSalutation,
      isDuplicateCustomer,
      getAllLeadSources,
      pushModalToStack,
      popModalFromStack,
      checktaxIdentificationNumber,
      fetchAllGSTINDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
