import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesInvoiceListingComp from '../../../../components/customer/invoice/Listing';
import SalesInvoiceFilterComp from '../../../../components/customer/invoice/Listing/salesInvoiceFilter';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import {
  fetchSalesInvoices, getSalesInvoiceCount, resetPaginatedData, postSalesInvoiceToXero, fetchSalesInvoiceDrafts, getSalesInvoiceDraftCount, resetDraftPaginatedData, deleteSalesInvoice, fetchProformaInvoices, getProformaInvoiceCount, updateUserStatus,
  fetchRecurringInvoices, getRecurringInvoiceCount, resetRecurringPaginatedData, deleteRecurringInvoices, inactiveRecurringInvoices, activeRecurringInvoices,
  fetchInvoicesReminder, getInvoiceReminderCount, resetInvoiceReminderPaginationData, deleteInvoiceReminder, updateIsActiveInvoicesReminder  , getEInvoiceListingDetail,
  cashProformaPayment
} from './action';
import {
  showModal, pushModalToStack, hideModal, popModalFromStack,
  fetchUserPreferences, saveUserPrefrences, deleteAuditTrail, createAuditTrail,
  fetchProjects
} from '../../../../actions/commonActions';
import AuditTimeLine from '../../../../components/drawer/AuditTimeLine';
import * as find from 'lodash.find';
import { fetchCustomers } from '../../Listing/action';
import { getAllStudentForm } from '../../../Student/studentFormList/action';
import EmailComponent from '../../../../containers/drawer/recurringInvoiceEmail';
import { getSchoolStudents, countSchoolStudents } from '../../../schoolManagement/student/action';

class SalesOrderListing extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 200,
      pageNumber: 1,
      isDataRendered: false,
      // activeTab: linkProps.activeTab,
      activeTabKey: (props.companyInfo?.industryName || "").toLowerCase() === "education" ? 'feeInvoice' : 'feeInvoice',
      module: linkProps.module,
      txPreferences: { txType: "salesInvoice", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['invoiceNumber', 'soNumber','eInvoiceGenerated', 'customerName','documentNumber', 'invoiceTotalAmount', 'invoiceDate' ,"acknowledgeNumber","acknowledgeDate","ewbDate","ewbNo","ewbDate"],
      txAvailableColumn: {
        isXeroUploaded: true,
        invoiceNumber: true,
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        customerName: true,
        invoiceTotalAmount: true,
        eInvoiceGenerated: true,
        status: true,
        documentNumber: true,
        totalPaymentReceived: true,
        invoiceDueAmount: true,
        paymentTermName: true,
        invoiceDate: true,
        invoiceDueDate: true,
        quotationNumber: true,
        // rfqNumber: true,
        userStatus: true,
        project: true,
        isCashInvoice: true,
        referenceNumber: true,
        dueDays: true,
        creditApplied: true,
        onlinePayment: true,
        invoiceReminder: true,
        recurringInvoice: true,
        acknowledgeNumber: true,
        acknowledgeDate: true,
        ewbDate: true,
        ewbNo: true,
        irn: true,

      },
    };
  }

  componentDidMount() {

    const linkProps = (this.props.location && this.props.location.state) || {};
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 200,
      filters: {
        module: linkProps.module, 
        formId: linkProps.module === "school" ? this.props.defaultSchoolForm : 0,
      }
    };
    fetchPaginationDataIfNeeded('fetchSalesInvoices', 'salesInvoiceList', this.props, payload);
    fetchPaginationDataIfNeeded('getEInvoiceListingDetail', 'einvoiceList', this.props, payload);
    // fetchPaginationDataIfNeeded('fetchProformaInvoices', 'proformaInvoiceList', this.props, payload);
    fetchDataIfNeeded('getSalesInvoiceCount', 'salesInvoiceCount', this.props, payload);
    // fetchDataIfNeeded('getProformaInvoiceCount', 'proformaInvoiceCount', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    if (linkProps.activeTab === "Draft") {
      fetchDataIfNeeded('getSalesInvoiceDraftCount', 'salesInvoiceDraftCount', this.props, payload);
      fetchPaginationDataIfNeeded('fetchSalesInvoiceDrafts', 'salesInvoiceDraftList', this.props, payload);
    }
    this.props.getRecurringInvoiceCount(payload);
    this.props.getInvoiceReminderCount(payload);
    fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, {...payload, isCompact: true}, true);
    fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
    if (linkProps.module === "school") {
    fetchPaginationDataIfNeeded("getAllStudentForm", "studentFormList", this.props, payload);
    }

    //this.props.getSchoolStudents(payload);
    //this.props.countSchoolStudents(payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "salesInvoice", templateName: "standard" });
      if (txPreferences && txPreferences.tablePreference) {
        let txColumns = ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [];
        txColumns = txColumns.length ? txColumns : this.state.txMandatoryColumns;
        const txColumnSetting = ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }
    if (props.studentFormList && props.studentFormList.length && !this.state.selectedformId) {
      let selectedForm ;
      if(this.props.defaultSchoolForm){
        selectedForm = find(props.studentFormList, { registrationFormSettingId: this.props.defaultSchoolForm }) || {};
        this.setState({
          selectedformName: selectedForm.formShortName, 
          selectedformId: selectedForm.registrationFormSettingId 
        });
      }
    }

  }

  render() {
    return (
      <div>
        {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.emailDetailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.salesInvoiceFilterDrawerVisible && <SalesInvoiceFilterComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        <SalesInvoiceListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    salesInvoiceList: state.salesInvoice.salesInvoiceList,
    einvoiceList: state.salesInvoice.einvoiceList,
    isLoading: state.common.isLoading,
    proformaInvoiceList: state.salesInvoice.proformaInvoiceList,
    proformaInvoiceCount: state.salesInvoice.proformaInvoiceCount,
    salesInvoiceCount: state.salesInvoice.salesInvoiceCount,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    salesInvoiceDraftList: state.salesInvoice.salesInvoiceDraftList,
    salesInvoiceDraftCount: state.salesInvoice.salesInvoiceDraftCount,
    recurringInvoiceList: state.salesInvoice.recurringInvoiceList,
    recurringInvoiceCount: state.salesInvoice.recurringInvoiceCount,
    invoiceReminderList: state.salesInvoice.invoiceReminderList,
    invoiceReminderCount: state.salesInvoice.invoiceReminderCount,
    customers: state.customer.customers,
    projectList: state.common.projectList,
    studentFormList: state.membershipReducer.studentFormList,
    defaultSchoolForm: state.common.defaultSchoolForm,
    schoolStudentList: state.school.schoolStudentList,
    schoolStudentsCount: state.school.schoolStudentCount,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getEInvoiceListingDetail,
  fetchSalesInvoices,
  getSalesInvoiceCount,
  resetPaginatedData,
  showModal,
  popModalFromStack,
  pushModalToStack,
  createAuditTrail,
  deleteAuditTrail,
  hideModal,
  postSalesInvoiceToXero,
  fetchUserPreferences,
  deleteSalesInvoice,
  getProformaInvoiceCount,
  saveUserPrefrences,
  fetchProformaInvoices,
  fetchSalesInvoiceDrafts,
  getSalesInvoiceDraftCount,
  resetDraftPaginatedData,
  updateUserStatus,
  fetchRecurringInvoices, 
  getRecurringInvoiceCount,
  resetRecurringPaginatedData, 
  deleteRecurringInvoices, 
  inactiveRecurringInvoices, 
  activeRecurringInvoices,
  fetchInvoicesReminder, 
  getInvoiceReminderCount, 
  resetInvoiceReminderPaginationData, 
  deleteInvoiceReminder, 
  updateIsActiveInvoicesReminder,
  fetchCustomers,
  fetchProjects, 
  getAllStudentForm,
  getSchoolStudents, 
  countSchoolStudents,
  cashProformaPayment
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderListing);
